import {TreeNode} from "@components/Flamegraph/FlamegraphData.ts";
import {FlamegraphState} from "@components/Flamegraph/FlamegraphState.ts";
import {FlamegraphTooltip} from "@components/Flamegraph/FlamegraphTooltip.tsx";
import {VisibilityOff} from "@mui/icons-material";

export function FlamegraphCellCompressed({
  nodes,
  state,
}: {
  nodes: Array<TreeNode>;
  state: FlamegraphState;
}) {
  const isHighlighted = state.isAnyNodeHighlighted(nodes);
  const isFocused = nodes.find((node) => state.focusedNodes.has(node)) != null;
  const haveHidden = nodes.find((node) => state.hiddenNodes.has(node)) != null;

  return (
    <FlamegraphTooltip entry={nodes} state={state}>
      <div
        className={"flame-cell" + (isFocused ? " flame-cell-focused" : "")}
        style={{
          background: isHighlighted ? "cornflowerblue" : "darkgray",
        }}
        onClick={() => state.setFocusedNode(nodes)}
      >
        {haveHidden && (
          <VisibilityOff style={{height: "0.8em", verticalAlign: "middle"}} />
        )}
        ...
      </div>
    </FlamegraphTooltip>
  );
}
