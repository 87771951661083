import {
  FilterNodesByIds,
  FlamegraphWidthStyle,
  GetNodeIds,
  TreeNode,
} from "@components/Flamegraph/FlamegraphData.ts";
import {FilteringOptionType, StacksFilter} from "@graphql/graphql.ts";
import {FlamegraphActions} from "src/components/Flamegraph/Flamegraph";

// All the nodes in a focused set have to be at the same depth.
export type FocusedNodeType = TreeNode | TreeNode[];
export type NodeSelector = (node: TreeNode) => void;

// Right now the focused entry info is in two place, since the autocomplete
// input also needs it. It should probably be merged when it's more clear
// how to display multiple focused nodes in the input.
export function GetFocusedNodesFromState(
  root: TreeNode,
  focusedNodeIDs: number[],
  filters: StacksFilter[],
): TreeNode[] {
  const stackPrefixFilter = filters.find(
    (f) => f.Type == FilteringOptionType.StackPrefix,
  );
  const binaryFilter = filters.find(
    (f) => f.Type == FilteringOptionType.BinaryId,
  );

  if (stackPrefixFilter) {
    const node = root.findNode(
      stackPrefixFilter.BinaryID!,
      stackPrefixFilter.StackPrefix!.split(",").map(Number),
    );
    if (!node) {
      // It's possible that the stack prefix filter is incompatible with the
      // current nodes. For example, the stack prefix could have come from
      // clicking on a flamegraph node when visualizing a different process'
      // snapshot, and that stack doesn't exist in snapshot of the process that
      // we're currently visualizing. In such a case, don't display any nodes in
      // the flamegraph.
      // TODO(mihai): this should be an explicit error probably
      console.warn("The focused node is probably from another process");
      return [root];
    }
    return [node];
  }

  if (binaryFilter) {
    return [root.findNode(binaryFilter.BinaryID!) || root];
  }
  const nodes = FilterNodesByIds(root, focusedNodeIDs);
  if (nodes.length) {
    return nodes;
  }
  return [root];
}

// Used to group various class to change the state that need to be passed to the
// Flamegraph.
export class FlamegraphState {
  root: TreeNode;
  actions: FlamegraphActions;
  focusedNodes: Set<TreeNode>;
  hiddenNodes: Set<TreeNode>;
  highlighterFilter: string;
  widthStyle: FlamegraphWidthStyle;
  setWidthStyle: (value: FlamegraphWidthStyle) => void;
  unit: string;

  constructor(
    root: TreeNode,
    focusedNodeIDs: number[], // If empty, we'll focus on the root.
    hiddenNodeIDs: number[],
    filters: StacksFilter[],
    actions: FlamegraphActions,
    highlighterFilter: string,
    widthStyle: FlamegraphWidthStyle,
    setWidthStyle: (value: FlamegraphWidthStyle) => void,
    unit: string,
  ) {
    this.root = root;
    this.actions = actions;
    this.hiddenNodes = new Set(FilterNodesByIds(root, hiddenNodeIDs));
    const focused = GetFocusedNodesFromState(root, focusedNodeIDs, filters);
    this.focusedNodes = new Set(focused);
    this.highlighterFilter = highlighterFilter;
    this.widthStyle = widthStyle;
    this.setWidthStyle = setWidthStyle;
    this.unit = unit;
  }

  setFocusedNode(node: FocusedNodeType) {
    this.actions.setFocused(node);
  }

  setHiddenNodes(nodes: Set<TreeNode>) {
    const nodeIDs = GetNodeIds([...nodes]);
    this.actions.setHidden(nodeIDs);
  }

  hideNode(node: TreeNode) {
    this.setHiddenNodes(new Set<TreeNode>([...this.hiddenNodes, node]));
  }

  unhideNode(node: TreeNode) {
    this.hiddenNodes.delete(node);
    this.setHiddenNodes(new Set<TreeNode>([...this.hiddenNodes]));
  }

  isSingleNodeHighlighted(
    node: TreeNode,
    includeChildren: boolean = false,
  ): boolean {
    if (!this.highlighterFilter) {
      return false;
    }
    const selfHighlighted = node.complete.includes(this.highlighterFilter);
    return (
      selfHighlighted ||
      (includeChildren && this.isAnyNodeHighlighted(node.children || []))
    );
  }

  isAnyNodeHighlighted(nodes: Array<TreeNode>): boolean {
    return (
      nodes.find((node) => this.isSingleNodeHighlighted(node, true)) != null
    );
  }
}
