import {useApolloClient, useQuery, useSuspenseQuery} from "@apollo/client";
import {TreeNode} from "@components/Flamegraph/FlamegraphData.ts";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {
  RecordingDatabaseDownloadButton,
  SnapshotDownloadButton,
} from "@components/download-buttons/index.ts";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  Link,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import {ProcessResolverProvider} from "@providers/processResolverProvider.tsx";
import {GET_RECORDING_DATABASE_URL} from "@util/queries.tsx";
import dayjs from "dayjs";
import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {Link as ReactLink, useNavigate, useParams} from "react-router-dom";
import {
  FilteringOptionType,
  FunctionSpec,
  StacksFilter,
  Tables_Kind,
} from "src/__generated__/graphql.ts";
import FunctionSpecDrawer, {
  FunctionSpecSkeleton,
} from "src/components/FunctionSpecDrawer.tsx";
import {Tables} from "src/components/tables/tables.tsx";
import {isDefined} from "src/components/util.tsx";
import {useSpec} from "src/providers/spec-provider.tsx";
import {SnapshotOpenerContext} from "src/templates/PageLayout/components/Header.tsx";
import {ProcessResolver} from "src/util/process-resolver.ts";
import {getFunctionSpec} from "src/util/spec.ts";
import {NANOS_PER_MILLI_BIGINT, ProcessInfo} from "src/util/util.ts";
import {
  SnapshotTab,
  useSnapshotState,
} from "../../providers/snapshot-state.tsx";
import {ROUTER_PATHS} from "../../router/router-paths.ts";
import GoroutinesTab from "./components/GoroutinesTab";
import {GET_SNAPSHOT, GET_STACKS} from "./gqlHelper.ts";

// SingleSnapshot renders a collection of goroutines: their stack traces and collected
// variables. The goroutines might come from snapshots taken from multiple
// processes. The component lets the user interact with the data, by filtering
// it and by editing the specs.
//
// The SingleSnapshot doesn't have props; it takes its input from URL params.
export default function SingleSnapshot(): React.JSX.Element {
  const spec = useSpec();
  const snapshotID = parseInt(useParams().snapshotID!);

  const {data: snapshotRes} = useSuspenseQuery(GET_SNAPSHOT, {
    variables: {snapshotID: snapshotID},
  });
  const recordingID = snapshotRes.getSnapshot.recordingID;
  const {data: recordingURLData, error: recordingURLError} = useSuspenseQuery(
    GET_RECORDING_DATABASE_URL,
    {
      variables: {id: recordingID},
      errorPolicy: "all",
    },
  );
  const recordingDatabaseDownloadButtonProps =
    recordingURLData?.getRecordingDatabaseURL
      ? {url: recordingURLData.getRecordingDatabaseURL}
      : {error: recordingURLError?.message ?? "Unknown error"};

  const processResolver = new ProcessResolver(
    snapshotRes.getSnapshot.snapshots.map(
      (s): ProcessInfo => ({
        ProcessID: s.processID,
        BinaryID: s.binaryID,
        FriendlyName: s.processFriendlyName,
        CaptureTimeNanos:
          BigInt(Date.parse(snapshotRes.getSnapshot.captureTime)) *
          NANOS_PER_MILLI_BIGINT,
        DuckDBProcessUUID: s.duckDBProcessUUID,
      }),
    ),
    snapshotRes.getSnapshot.binaries,
  );
  const snapshotState = useSnapshotState();
  const selectedFrame = snapshotState.state.selectedFrame;

  const navigate = useNavigate();

  const client = useApolloClient();
  const onOpenNewSnapshotClick = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement>,
      snapshotID: number,
    ): Promise<void> => {
      // Check if there are any process snapshots that need rewriting. If there
      // are any filters of type ProcessSnapshot or Goroutine, we look in the
      // new snapshot for process snapshots with the same friendly name. If we
      // find one, we rewrite the filter to the ID of that new process snapshot.
      // Otherwise, we drop the filter.

      // Map from filter idx to the ID of the new process that should be
      // rewritten into that filter.
      const newSnapProcessIDs = new Map<number, number>();
      // Map from filter idx to the friendly name of the process snapshot in that filter.
      const processSnapshotFriendlyNames = new Map<number, string>();
      const isFilterWithSnapshotID = (f: StacksFilter) =>
        f.Type == FilteringOptionType.ProcessSnapshot ||
        f.Type == FilteringOptionType.Goroutine;

      snapshotState.state.filters.forEach((f, filterIdx) => {
        if (isFilterWithSnapshotID(f)) {
          processSnapshotFriendlyNames.set(
            filterIdx,
            processResolver.resolveProcessIDOrThrow(f.ProcessID!).FriendlyName,
          );
        }
      });
      // If there are any ProcessSnapshot or Goroutine filters, populate
      // newSnapProcessIDs.
      if (processSnapshotFriendlyNames.size > 0) {
        const {data: collectionData} = await client.query({
          query: GET_SNAPSHOT,
          variables: {snapshotID},
        });
        const newProcSnaps = collectionData.getSnapshot.snapshots?.map((s) => ({
          processID: s.processID,
          friendlyName: s.processFriendlyName,
        }));
        // Map from filter idx to the ID of the new process snapshot that should
        // go into that position.
        processSnapshotFriendlyNames.forEach((friendlyName, filterIdx) => {
          const newProcSnap = newProcSnaps?.find(
            (s) => s.friendlyName == friendlyName,
          );
          if (newProcSnap != undefined) {
            newSnapProcessIDs.set(filterIdx, newProcSnap.processID);
          }
        });
      }

      // Rewrite Snapshot filters.
      const newFilters = snapshotState.state.filters
        .map((f, filterIdx): StacksFilter | undefined => {
          if (!isFilterWithSnapshotID(f)) {
            return f;
          }

          // If there's a process in the new snapshot with the corresponding friendly
          // name, use it. Otherwise, drop the filter.
          const newProcessID = newSnapProcessIDs.get(filterIdx);
          if (newProcessID) {
            return {...f, ProcessID: newProcessID};
          } else {
            return undefined;
          }
        })
        .filter(isDefined);

      const newParams = snapshotState.computeSearchParams({
        filters: newFilters,
      });
      const newWindow = event.ctrlKey || event.button == 1;
      if (!newWindow) {
        navigate({
          pathname: `/snapshots/${snapshotID}`,
          search: newParams.toString(),
        });
      } else {
        window.open(
          `/#/snapshots/${snapshotID}?${newParams.toString()}`,
          "_blank",
        );
      }
    },
    [snapshotState, client, navigate],
  );
  // Install the custom handler for opening new snapshots.
  const snapshotOpenerConfig = useContext(SnapshotOpenerContext);
  useEffect(() => {
    return snapshotOpenerConfig.setOpenSnapshotHandler(onOpenNewSnapshotClick);
  }, [onOpenNewSnapshotClick, snapshotOpenerConfig]);

  const {data: snapshotData, error: snapshotError} = useQuery(GET_STACKS, {
    variables: {
      snapshotID: snapshotID,
      filters: snapshotState.state.filters,
    },
    // The data returned here is very big. Apollo does shockingly poorly with
    // large data sets. We would like this to be cached, probably, but we don't
    // want to cache it in the Apollo cache because it's too big.
    fetchPolicy: "no-cache",
  });
  if (snapshotError) {
    throw snapshotError;
  }
  // Get the spec for the (function corresponding to the) selected frame. If we
  // don't currently have a spec for this function, we'll start with an empty
  // one.
  let selectedFunctionSpec:
    | (FunctionSpecSkeleton & {existsInDatabase: false})
    | (FunctionSpec & {existsInDatabase: true})
    | undefined;
  if (selectedFrame != undefined) {
    const specFromDB = getFunctionSpec(spec, selectedFrame.functionName);
    if (specFromDB) {
      selectedFunctionSpec = {
        ...specFromDB,
        existsInDatabase: true,
      };
    } else {
      selectedFunctionSpec = {
        funcQualifiedName: selectedFrame.functionName,
        existsInDatabase: false,
      };
    }
  }

  const [clearNodeHighlight, setClearNodeHighlight] = useState(
    undefined as (() => () => void) | undefined,
  );
  // If the selected frame is undefined, we don't want to highlight any node in
  // the flamegraph.
  if (selectedFunctionSpec === undefined && clearNodeHighlight !== undefined) {
    clearNodeHighlight();
    setClearNodeHighlight(undefined);
  }

  const setSelectedFrame = (selectedFrame: TreeNode) => {
    if (selectedFrame.nodeType != "frame") {
      throw new Error("selected node is not a frame");
    }
    if (selectedFrame.pc == undefined) {
      throw new Error("selected frame is missing pc");
    }
    snapshotState.setSelectedFrame({
      BinaryID: selectedFrame.binaryID,
      FuncQualifiedName: selectedFrame.complete,
      File: selectedFrame.file,
      Line: selectedFrame.line,
      Inlined: selectedFrame.inlined,
      PC: selectedFrame.pc,
    });
  };

  return (
    <>
      {/*This stack makes the download database button float to the right.*/}
      <Stack direction={"row"} justifyContent="space-between">
        <Stack flexDirection="row" alignItems={"center"} gap={2}>
          <Breadcrumbs>
            <Link component={ReactLink} to={ROUTER_PATHS.recordings}>
              Snapshots
            </Link>
            <span>
              {snapshotRes.getSnapshot.id} -{" "}
              {snapshotRes.getSnapshot.environment} captured{" "}
              {dayjs(snapshotRes.getSnapshot.captureTime).format(
                "dddd, MMM, YYYY",
              )}{" "}
              at{" "}
              {dayjs(snapshotRes.getSnapshot.captureTime).format("h:mm:ss A z")}
            </span>
          </Breadcrumbs>

          <Stack direction="row" alignItems="center" gap={1}>
            <Card sx={{px: 2, py: 1}} square>
              <Typography variant="body3" color="textSecondary">
                Programs:{" "}
              </Typography>
              <Typography variant="body3" textOverflow="ellipsis">
                {snapshotRes.getSnapshot.programs.join(", ")}
              </Typography>
            </Card>
            <HelpCircle tip={"The programs included in this snapshot"} />
          </Stack>
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <SnapshotDownloadButton snapshotID={snapshotID} />
          <RecordingDatabaseDownloadButton
            {...recordingDatabaseDownloadButtonProps}
          />
        </Stack>
      </Stack>

      <ProcessResolverProvider value={processResolver}>
        <Stack flexGrow={1}>
          <TabContext value={snapshotState.state.snapshotTab.toString()}>
            <TabList variant="standard">
              <Tab
                value={"goroutines"}
                label="Goroutines"
                component={ReactLink}
                to={snapshotState.setTabToGoroutinesURL()}
              />
              <Tab
                value={"tables"}
                label="Captured data"
                component={ReactLink}
                to={snapshotState.setTabToTablesURL(
                  // clearSelectedTable - clear it if we are already on the Tables tab.
                  snapshotState.state.snapshotTab == SnapshotTab.Tables,
                )}
              />
            </TabList>

            <Divider color="gradient"></Divider>

            <Box flexGrow={1}>
              <TabPanel
                value={"goroutines"}
                sx={{height: "100%", py: 0, m: 0, px: 0}}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  height={"100%"}
                  sx={{overflowY: "auto"}}
                >
                  <GoroutinesTab
                    processSnapshots={snapshotRes.getSnapshot.snapshots}
                    stacksAndSchemas={snapshotData?.getStacks}
                    setSelectedFrame={setSelectedFrame}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={"tables"} sx={{h: "100%", py: 0, m: 0, px: 0}}>
                <ErrorBoundary
                  fallbackRender={({error}) => (
                    <Box>
                      <Typography color={"error"}>
                        Failed to generate tables: {error.message}
                      </Typography>
                    </Box>
                  )}
                >
                  <Suspense fallback={<div>Generating tables...</div>}>
                    <Tables
                      snapshotID={snapshotState.state.snapshotID}
                      tablesKind={Tables_Kind.StackFrames}
                      controller={snapshotState.tablesController}
                    />
                  </Suspense>
                </ErrorBoundary>
              </TabPanel>
            </Box>
          </TabContext>
        </Stack>
      </ProcessResolverProvider>

      {selectedFunctionSpec && (
        <FunctionSpecDrawer
          display={selectedFunctionSpec}
          inlined={selectedFrame?.inlined}
          pc={selectedFrame?.pc}
          showDeleteButton={selectedFunctionSpec.existsInDatabase ?? false}
          // binaryID will be used by FunctionSpecDrawer only when selectedFrame is set.
          binaryID={selectedFrame!.binaryID}
          onClose={() => {
            snapshotState.clearSelectedFrame();
            if (clearNodeHighlight) {
              clearNodeHighlight();
            }
          }}
        />
      )}
    </>
  );
}
