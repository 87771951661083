import posthog from "posthog-js";

// Change to true to enable PostHog for local development.
const alwaysEnabled = false;

export function initPosthog() {
  if (
    !alwaysEnabled &&
    (import.meta.env.DEV ||
      window.location.host.includes("127.0.0.1") ||
      window.location.host.includes("localhost"))
  ) {
    return;
  }
  posthog.init("phc_YbJvIh646tNnAhgjCr2RNod9PrUhel6I3thzkOpRmbp", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "always",
  });
}
