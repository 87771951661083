import React, {useCallback, useContext, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Alert, Box, Button, Stack, styled, Typography} from "@mui/material";
import {
  AppConfigContext,
  getApiUrlForPath,
  getAppXUrl,
  setAuthHeaders,
} from "@providers/app-config-provider";
import {useNavigate} from "react-router-dom";
import {z} from "zod";

// Gzip magic header (RFC 1952)
// Reference: https://www.rfc-editor.org/rfc/rfc1952#page-6
const GZIP_MAGIC_HEADER = [0x1f, 0x8b];

const StyledDropzone = styled(Box)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.component,
}));

async function isGzip(file: File): Promise<boolean> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (
        e.target &&
        e.target.result &&
        e.target.readyState === FileReader.DONE
      ) {
        if (typeof e.target.result === "string") {
          throw new Error(
            "FileReader.result is a string, but it should be an ArrayBuffer",
          );
        }
        const uint8Array = new Uint8Array(e.target.result);
        resolve(
          uint8Array[0] === GZIP_MAGIC_HEADER[0] &&
            uint8Array[1] === GZIP_MAGIC_HEADER[1],
        );
      } else {
        resolve(false);
      }
    };
    reader.readAsArrayBuffer(file.slice(0, 2));
  });
}

// ImportTrace renders the UI for importing an execution trace file and
// generating a recording based on it.
export default function ImportTrace(): React.JSX.Element {
  const navigate = useNavigate();
  const appConfig = useContext(AppConfigContext);
  const [isLoading, setIsLoading] = useState(false);
  const appCfg = useContext(AppConfigContext);
  const auth = appCfg.Auth;
  const apiUrl = getApiUrlForPath(appCfg.APIBaseURL, "/import/trace");
  const [error, setError] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setError(undefined);
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
    disabled: isLoading,
  });

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!file) {
        return;
      }

      setIsLoading(true);

      try {
        const headers = await setAuthHeaders({}, auth);
        const formData = new FormData();
        const isGzipped = await isGzip(file);

        // Create a new Blob with the correct type (gzip or octet-stream).
        const fileBlob = new Blob([file], {
          type: isGzipped ? "application/gzip" : "application/octet-stream",
        });

        formData.append("trace", fileBlob, file.name);
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
          headers: headers,
        });

        const responseText = await response.text();
        if (!response.ok) {
          throw new Error(
            `upload error: ${response.statusText} ${response.status}: ${responseText}`,
          );
        }

        // Parse the response into the recording ID.
        const parsedResponse = responseSchema.parse(JSON.parse(responseText));
        window.location.href = `${getAppXUrl(appConfig)}/recordings/${parsedResponse.recordingID}/goroutines`;
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : "Unknown error";
        setError(`Error uploading file: ${errorMessage}`);
      } finally {
        setFile(undefined);
        setIsLoading(false);
      }
    },
    [file, apiUrl, auth, setError],
  );

  return (
    <div className="generate-trace-db">
      <h1>Upload execution trace</h1>
      {error && <Alert severity="error">{error}</Alert>}
      <form
        onSubmit={(e) => {
          void handleSubmit(e);
        }}
      >
        <StyledDropzone {...getRootProps({className: "dropzone"})}>
          <input type="file" {...getInputProps()} />
          {file ? (
            <p>{file.name}</p>
          ) : (
            <p>
              Drag or select a Go execution trace file (commonly downloaded from
              /debug/pprof/trace)
            </p>
          )}
        </StyledDropzone>
        <Stack direction="row" spacing={1} alignItems={"center"} sx={{mt: 2}}>
          <Button
            sx={{mt: 2}}
            type="submit"
            disabled={!file || isLoading}
            variant={"contained"}
          >
            {isLoading ? "Processing..." : "Upload"}
          </Button>
          <Typography variant={"muted"}>
            After uploading, the new execution trace will appear as a recording
            in the list of recordings.
          </Typography>
        </Stack>
      </form>
    </div>
  );
}

const responseSchema = z.object({
  recordingID: z.number(),
});
