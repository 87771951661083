import {FlamegraphCell} from "./FlamegraphCell.tsx";
import {
  FlamegraphWidthStyle,
  GetNodeIds,
  NodeOrRangeWithWeight,
  TreeNode,
} from "./FlamegraphData.ts";
import {BuildFlamegraphRows} from "./FlamegraphProcessing.ts";
import {FlamegraphHeader} from "./FlamegraphHeader.tsx";
import "./flamegraph.css";
import {
  FlamegraphState,
  FocusedNodeType,
  NodeSelector,
} from "@components/Flamegraph/FlamegraphState.ts";
import {useState} from "react";
import {FlamegraphCellCompressed} from "@components/Flamegraph/FlamegraphCellCompressed.tsx";
import {StacksFilter} from "src/__generated__/graphql";

interface FlamegraphRowProps {
  row: Array<NodeOrRangeWithWeight>;
  state: FlamegraphState;
}

function FlamegraphRow({row, state}: FlamegraphRowProps) {
  const children = row.map(({node, weight}, index) => {
    const content =
      node &&
      (Array.isArray(node) ? (
        <FlamegraphCellCompressed
          nodes={node}
          state={state}
          key={"nodes-" + GetNodeIds(node).join("-")}
        />
      ) : (
        <FlamegraphCell node={node} state={state} key={"node" + node.uid} />
      ));

    return (
      <div
        className="flame-column"
        style={{width: 100 * weight + "%"}}
        key={"node-" + index}
      >
        {content}
      </div>
    );
  });

  return <div className="flame-row">{children}</div>;
}

export interface FlamegraphProps {
  root: TreeNode;
  // If isReversed is true, the rows are reversed, with the root at the bottom.
  // This can be used to create "butterfly" views from two separate graphs.
  isReversed?: boolean;
  highlighterFilter: string;
  actions: FlamegraphActions;
  focusedNodes: number[];
  hiddenNodes: number[];
  filters: StacksFilter[];

  // The unit for the node's values. The nodes might count things like
  // "goroutines" or "events".
  unit: string;
}

export interface FlamegraphActions {
  setFocused: (node: FocusedNodeType) => void;
  // If onVariableButtonClick is set, a "Variables" button will be shown in the
  // tooltip, and this handler is called on click.
  onVariableButtonClick?: NodeSelector;
  setHidden: (nodeIDs: number[]) => void;
}

export function Flamegraph(props: FlamegraphProps) {
  const [widthStyle, setWidthStyle] = useState<FlamegraphWidthStyle>(
    FlamegraphWidthStyle.Linear,
  );

  const state = new FlamegraphState(
    props.root,
    props.focusedNodes,
    props.hiddenNodes,
    props.filters,
    props.actions,
    props.highlighterFilter,
    widthStyle,
    setWidthStyle,
    props.unit,
  );

  const rows = BuildFlamegraphRows(props.root, state);

  if (props.isReversed) {
    rows.reverse();
  }

  return (
    <div className="flame-graph">
      <FlamegraphHeader state={state} />
      {rows.map((row, index) => (
        <FlamegraphRow row={row} state={state} key={"row-" + index} />
      ))}
    </div>
  );
}
