import IconSnapshots from "@components/icons/IconSnapshots.tsx";
import IconAddAPhoto from "@mui/icons-material/AddAPhoto";
import IconSpec from "@components/icons/IconSpec.tsx";
import IconAgents from "@components/icons/IconAgents.tsx";
import IconBinaries from "@components/icons/IconBinaries.tsx";
import IconSchedule from "@components/icons/IconSchedule.tsx";
import {pathComponent, ROUTER_PATHS} from "../../router/router-paths.ts";
import {MenuItem} from "./components/MenuItem.tsx";
import {CloudUpload} from "@mui/icons-material";

export const menuItems: MenuItem[] = [
  {
    matcher: (location: string) =>
      location == "/" ||
      location.includes(ROUTER_PATHS.recordings) ||
      location.includes(pathComponent(ROUTER_PATHS.snapshot)) ||
      location.includes(pathComponent(ROUTER_PATHS.log)),
    link: ROUTER_PATHS.recordings,
    name: "Recordings",
    icon: <IconSnapshots />,
  },
  {
    matcher: (location: string) =>
      location.includes(pathComponent(ROUTER_PATHS.captureRecording)),
    link: ROUTER_PATHS.captureRecording,
    name: "Capture",
    icon: <IconAddAPhoto sx={{color: "currentColor"}} />,
  },
  {
    matcher: (location: string) =>
      location.includes(ROUTER_PATHS.snapshotSpecEditor),
    link: ROUTER_PATHS.snapshotSpecEditor,
    name: "Spec",
    icon: <IconSpec />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.agents),
    link: ROUTER_PATHS.agents,
    name: "Agents",
    icon: <IconAgents />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.binaries),
    link: ROUTER_PATHS.binaries,
    name: "Binaries",
    icon: <IconBinaries />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.schedule),
    link: ROUTER_PATHS.schedule,
    name: "Schedule",
    icon: <IconSchedule />,
  },
  {
    matcher: (location: string) => location.includes(ROUTER_PATHS.importTrace),
    link: ROUTER_PATHS.importTrace,
    name: "Upload",
    icon: <CloudUpload />,
  },
];
