import {Card, CardContent, CardHeader, Link, Typography} from "@mui/material";
import React from "react";
import {Link as ReactLink} from "react-router-dom";

// DeployAgentsInstructions provides static instructions about how to deploy
// side-eye-agent.
export function DeployAgentsInstructions() {
  return (
    <Card>
      <CardHeader title="Agents instructions" />
      <CardContent component={Typography} variant="body2">
        <code>side-eye-agent</code> needs to be installed and running on all the
        machines hosting processes that you want to monitor. To install on each
        machine:
        <ul>
          <li>
            <span>
              Navigate to the{" "}
              <Link
                component={ReactLink}
                to={{
                  pathname: "/login",
                }}
              >
                login page
              </Link>
              .
            </span>
          </li>
          <li>Copy the API token corresponding to your organization.</li>
          <li>
            This token is shared by all users with email addresses at the same
            domain.
          </li>
          <li>
            <span>
              Run <code>curl https://sh.side-eye.io | sh</code> and paste the
              API token when prompted.
              <ul>
                <li>
                  Or pass the API token to the installation script directly:
                  <br />
                  <code>
                    {
                      "curl https://sh.side-eye.io | SIDE_EYE_API_TOKEN=<token> sh"
                    }
                  </code>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </CardContent>
    </Card>
  );
}
