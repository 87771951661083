import {Flamegraph, ParseTreeData, TreeNode} from "src/components/Flamegraph";
import React, {Suspense} from "react";
import {useQuery, useSuspenseQuery} from "@apollo/client";
import {gql} from "src/__generated__";
import {toastError} from "@components/tables/util";
import {useParams, useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab, Typography} from "@mui/material";
import {ErrorBoundary} from "react-error-boundary";
import {Tables} from "@components/tables/tables";
import {FunctionSpec, Tables_Kind} from "@graphql/graphql";
import {ProcessResolver} from "@util/process-resolver";
import {NANOS_PER_MILLI_BIGINT, ProcessInfo} from "@util/util";
import {ProcessResolverProvider} from "@providers/processResolverProvider";
import {RecordingProvider} from "@providers/recordingProvider";
import {
  computeSearchParams,
  JSONParamUpdater,
  stateFromURL,
  URLState,
  ZodParamUpdater,
} from "@util/url";
import {FocusedNodeType} from "../../components/Flamegraph/FlamegraphState";
import {GetNodeIds} from "../../components/Flamegraph/FlamegraphData";
import {z} from "zod";
import FunctionSpecDrawer, {
  FunctionSpecSkeleton,
} from "../../components/FunctionSpecDrawer";
import {getFunctionSpec} from "../../util/spec";
import {useSpec} from "../../providers/spec-provider";
import {frameReferenceSchema} from "../../providers/snapshot-state";

const GET_TREE_FOR_CPU_PROFILE = gql(/* GraphQL */ `
  query GetTreeForCPUProfile($profileID: ID!, $filters: [StacksFilter!]) {
    getTreeForCPUProfile(profileID: $profileID, filters: $filters)
  }
`);

const GET_CPU_PROFILE = gql(/* GraphQL */ `
  query GetCpuProfile($id: ID!) {
    getCpuProfile(id: $id) {
      id
      recordingID
      eventLogID
      eventLogStreamID
      processes {
        processID
        binaryID
        processFriendlyName
        captureTime
        duckDBProcessUUID
      }
    }
  }
`);

export default function CPUProfile() {
  const pathParams = useParams();
  const profileID = parseInt(pathParams.profileID!);
  const [tab, setTab] = React.useState("flamegraph");

  const {data: profileRes} = useSuspenseQuery(GET_CPU_PROFILE, {
    variables: {
      id: profileID,
    },
  });

  if (profileRes.getCpuProfile.eventLogID == null) {
    throw new Error("CPU profile does not have an event log");
  }
  if (profileRes.getCpuProfile.eventLogStreamID == null) {
    throw new Error("CPU profile does not have an event log stream");
  }

  const processResolver = new ProcessResolver(
    profileRes.getCpuProfile.processes.map(
      (s): ProcessInfo => ({
        ProcessID: s.processID,
        BinaryID: s.binaryID,
        FriendlyName: s.processFriendlyName,
        CaptureTimeNanos:
          BigInt(Date.parse(s.captureTime)) * NANOS_PER_MILLI_BIGINT,
        DuckDBProcessUUID: s.duckDBProcessUUID,
      }),
    ),
    [] /* binaries */,
  );
  return (
    <RecordingProvider
      value={{recordingID: profileRes.getCpuProfile.recordingID}}
    >
      <ProcessResolverProvider value={processResolver}>
        <TabContext value={tab}>
          <Box sx={{borderBottom: 1, borderColor: "divider"}}>
            <TabList onChange={(_ev, value) => setTab(value)}>
              <Tab label="Flame graph" value="flamegraph" />
              <Tab label="Captured data" value="tables" />
            </TabList>
          </Box>
          <TabPanel value="flamegraph">
            <FlameGraphTab profileID={profileID} />
          </TabPanel>
          <TabPanel value="tables">
            <ProfileTables
              logID={profileRes.getCpuProfile.eventLogID}
              streamID={profileRes.getCpuProfile.eventLogStreamID}
            />
          </TabPanel>
        </TabContext>
      </ProcessResolverProvider>
    </RecordingProvider>
  );
}

// paramUpdaters is the mapping of URL parameters to an object that have get and
// update methods to read and write the URL parameters as well as a param string
// property with the name of the parameter.
const paramUpdaters = {
  focusedNodes: new JSONParamUpdater<number[]>("focusedNodes"),
  hiddenNodes: new JSONParamUpdater<number[]>("hiddenNodes"),
  selectedFrame: new ZodParamUpdater("selectedFrame", frameReferenceSchema),
};

type State = URLState<typeof paramUpdaters>;

function FlameGraphTab({profileID}: {profileID: number}): React.JSX.Element {
  const spec = useSpec();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlProps: State = stateFromURL(searchParams, paramUpdaters);

  const {data, loading, error} = useQuery(GET_TREE_FOR_CPU_PROFILE, {
    variables: {
      profileID,
      // TODO: support filtering
      filters: undefined,
    },
  });
  let flamegraphData: TreeNode | undefined;
  if (error) {
    toastError(error);
  } else if (data) {
    // TODO: Parsing the data might be expensive. We shouldn't do it on every
    // render; we should find a way to memoize it or do it only after a new
    // query has actually been run.
    flamegraphData = ParseTreeData(data.getTreeForCPUProfile);
  }

  // Get the spec for the (function corresponding to the) selected frame. If we
  // don't currently have a spec for this function, we'll start with an empty
  // one.
  let selectedFunctionSpec:
    | (FunctionSpecSkeleton & {existsInDatabase: false})
    | (FunctionSpec & {existsInDatabase: true})
    | undefined;
  const selectedFrame = urlProps.selectedFrame;
  if (selectedFrame != undefined) {
    const specFromDB = getFunctionSpec(spec, selectedFrame.functionName);
    if (specFromDB) {
      selectedFunctionSpec = {
        ...specFromDB,
        existsInDatabase: true,
      };
    } else {
      selectedFunctionSpec = {
        funcQualifiedName: selectedFrame.functionName,
        existsInDatabase: false,
      };
    }
  }

  const setFocused = (node: FocusedNodeType) => {
    const root = flamegraphData!;
    if (Array.isArray(node) && node.length === 1) {
      // Single focus is saved differently
      node = node[0];
    }

    if (Array.isArray(node)) {
      setSearchParams(
        computeSearchParams(
          searchParams,
          {focusedNodes: GetNodeIds(node)},
          paramUpdaters,
        ),
      );
      return;
    }

    if (node === root) {
      // Either we're in the root or no node is selected. Wipe the URL state.
      setSearchParams(
        computeSearchParams(
          searchParams,
          {focusedNodes: undefined},
          paramUpdaters,
        ),
      );
      return;
    }

    setSearchParams(
      computeSearchParams(
        searchParams,
        {focusedNodes: [node.uid]},
        paramUpdaters,
      ),
    );
  };

  const setHidden = (nodeIDs: number[]): void => {
    setSearchParams(
      computeSearchParams(
        searchParams,
        {hiddenNodes: nodeIDs.length > 0 ? nodeIDs : undefined},
        paramUpdaters,
      ),
    );
  };

  // onShowVariablesClick is called when the "Variables" button is clicked on a
  // frame in the flame graph.
  const onShowVariablesClick = (selectedFrame: TreeNode): void => {
    if (selectedFrame.nodeType != "frame") {
      throw new Error("selected node is not a frame");
    }
    if (selectedFrame.pc == undefined) {
      throw new Error("selected frame is missing pc");
    }
    setSearchParams(
      computeSearchParams(
        searchParams,
        {
          selectedFrame: {
            functionName: selectedFrame.complete,
            inlined: selectedFrame.inlined,
            pc: selectedFrame.pc,
            binaryID: selectedFrame.binaryID,
          },
        },
        paramUpdaters,
      ),
    );
  };

  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : error ? (
        <>Error: {error.message}</>
      ) : !flamegraphData ? (
        <>No stacks collected</>
      ) : (
        <Box>
          <Flamegraph
            root={flamegraphData}
            unit={"goroutines"}
            // TODO: support filtering and actions
            // TODO: highlight selected node
            highlighterFilter={""}
            filters={[]}
            hiddenNodes={urlProps.hiddenNodes ?? []}
            focusedNodes={urlProps.focusedNodes ?? []}
            actions={{
              setHidden,
              setFocused,
              onVariableButtonClick: onShowVariablesClick,
            }}
          />
        </Box>
      )}

      {selectedFunctionSpec && (
        <FunctionSpecDrawer
          display={selectedFunctionSpec}
          inlined={selectedFrame?.inlined}
          pc={selectedFrame?.pc}
          showDeleteButton={selectedFunctionSpec.existsInDatabase ?? false}
          // binaryID will be used by FunctionSpecDrawer only when selectedFrame is set.
          binaryID={selectedFrame!.binaryID}
          onClose={() => {
            setSearchParams(
              computeSearchParams(
                searchParams,
                {selectedFrame: undefined},
                paramUpdaters,
              ),
            );
          }}
        />
      )}
    </>
  );
}

// ProfileTables render the tables generated from the data collected from the
// stack traces of a CPU profile.
function ProfileTables({
  logID,
  streamID,
}: {
  logID: number;
  streamID: number;
}): React.JSX.Element {
  return (
    <ErrorBoundary
      fallbackRender={({error}) => (
        <Box>
          <Typography color={"error"}>
            Failed to generate tables: {error.message}
          </Typography>
        </Box>
      )}
    >
      <Suspense fallback={<div>Generating tables...</div>}>
        <Tables
          logID={logID}
          streamID={streamID}
          tablesKind={Tables_Kind.StackFrames}
        />
      </Suspense>
    </ErrorBoundary>
  );
}
