import {Button, Link, Tooltip} from "@mui/material";

export type FailedDatabaseDownloadButtonProps = {
  error: string;
};

export type EnabledDatabaseDownloadButtonProps = {
  url: string;
};

export type RecordingDatabaseDownloadButton =
  | FailedDatabaseDownloadButtonProps
  | EnabledDatabaseDownloadButtonProps;

export function RecordingDatabaseDownloadButton(
  props: RecordingDatabaseDownloadButton,
) {
  if ("error" in props) {
    return (
      <DatabaseDownloadButton
        opacity={0.5}
        tooltip={`Database unavailable: ${props.error}`}
      />
    );
  }
  return (
    <DatabaseDownloadButton
      url={props.url}
      tooltip={"Download a DuckDB database with this recording's data"}
    />
  );
}

function DatabaseDownloadButton({
  url,
  tooltip,
  opacity,
}: {
  url?: string;
  tooltip: string;
  opacity?: number;
}) {
  return (
    <Tooltip title={tooltip}>
      <span style={opacity ? {opacity} : undefined}>
        <Button variant="outlined" color="info" disabled={!url}>
          <Link href={url ?? ""}>
            <img src={"/duckdb.png"} alt="DuckDB" />
          </Link>
        </Button>
      </span>
    </Tooltip>
  );
}
