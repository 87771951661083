import React from "react";
import {useQuery} from "@apollo/client";
import {GET_STACKS_FOR_LOG} from "src/pages/EventLog/gql";
import {Flamegraph, ParseTreeData} from "@components/Flamegraph";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle";
import FunctionInLogSpecSelector from "@components/FunctionInLogSpecSelector";
import {GetLogMetaQuery} from "@graphql/graphql";
import {
  computeSearchParams,
  JSONParamUpdater,
  stateFromURL,
  URLState,
} from "@util/url";
import {useSearchParams} from "react-router-dom";
import {FocusedNodeType} from "./Flamegraph/FlamegraphState";
import {GetNodeIds} from "./Flamegraph/FlamegraphData"; // paramUpdaters is the mapping of URL parameters to an object that have get and

// paramUpdaters is the mapping of URL parameters to an object that have get and
// update methods to read and write the URL parameters as well as a param string
// property with the name of the parameter.
const paramUpdaters = {
  focusedNodes: new JSONParamUpdater<number[]>("focusedNodes"),
  hiddenNodes: new JSONParamUpdater<number[]>("hiddenNodes"),
};

type State = URLState<typeof paramUpdaters>;

export default function EventsFlamegraph({
  log,
  poll,
  selectedFunc,
  setSelectedFunc,
  startExpanded,
}: {
  log: GetLogMetaQuery["getLog"];
  poll: boolean;
  selectedFunc: string | undefined;
  setSelectedFunc: (funcQualifiedName: string | undefined) => void;
  startExpanded: boolean;
}): React.JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const stateProps: State = stateFromURL(searchParams, paramUpdaters);
  // Keep track of the value of poll at the last render.
  const pollRef = React.useRef(poll);

  const {loading, error, data, refetch} = useQuery(GET_STACKS_FOR_LOG, {
    variables: {logID: log.id, funcQualifiedName: selectedFunc},
    // Disable polling by setting it to a really high number. Switching from
    // 1000 -> undefined does not work:
    // https://github.com/apollographql/apollo-client/issues/11120
    pollInterval: poll ? 3000 : 100000000,
  });

  // When polling switches from true -> false, refetch the data one last time.
  if (pollRef.current == true && !poll) {
    void refetch();
  }
  pollRef.current = poll;

  const setFocused = (node: FocusedNodeType) => {
    const root = flamegraphData!;
    if (Array.isArray(node) && node.length === 1) {
      // Single focus is saved differently
      node = node[0];
    }

    if (Array.isArray(node)) {
      setSearchParams(
        computeSearchParams(
          searchParams,
          {focusedNodes: GetNodeIds(node)},
          paramUpdaters,
        ),
      );
      return;
    }

    if (node === root) {
      // Either we're in the root or no node is selected. Wipe the URL state.
      setSearchParams(
        computeSearchParams(
          searchParams,
          {focusedNodes: undefined},
          paramUpdaters,
        ),
      );
      return;
    }

    setSearchParams(
      computeSearchParams(
        searchParams,
        {focusedNodes: [node.uid]},
        paramUpdaters,
      ),
    );
  };

  const setHidden = (nodeIDs: number[]): void => {
    setSearchParams(
      computeSearchParams(
        searchParams,
        {hiddenNodes: nodeIDs.length > 0 ? nodeIDs : undefined},
        paramUpdaters,
      ),
    );
  };

  const flamegraphData =
    data &&
    data.getStacksForLog &&
    ParseTreeData(data.getStacksForLog.treeJSON);

  return (
    <Accordion defaultExpanded={startExpanded}>
      <AccordionSummary>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          Flame graph
          <HelpCircle
            tip={`If stack traces were captured for events, this flame graph aggregates all the stacks.`}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <>Loading...</>
        ) : error ? (
          <>Error: {error.message}</>
        ) : !flamegraphData ? (
          <>No stacks collected</>
        ) : (
          <Stack direction={"column"} spacing={3}>
            <FunctionInLogSpecSelector
              log={log}
              clearable={true}
              value={selectedFunc}
              onChange={setSelectedFunc}
            />
            <Flamegraph
              root={flamegraphData}
              unit={"events"}
              // TODO: figure out all the arguments to this flame graph
              highlighterFilter={""}
              filters={[]}
              hiddenNodes={stateProps.hiddenNodes ?? []}
              focusedNodes={stateProps.focusedNodes ?? []}
              actions={{
                setHidden,
                setFocused,
              }}
            />
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
