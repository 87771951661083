import {useApolloClient} from "@apollo/client";
import ExpressionTree, {treeNode} from "@components/ExpressionTree";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {TypeInfo, TypeSpec, TypeSpecInput} from "src/__generated__/graphql.ts";
import {buildTree, maybeAddType, treeNodes} from "./available-vars-helpers";

export type StructSpecEditorInnerProps = {
  binaryID: string;
  disabled?: boolean;
  hoisting: boolean;
  spec: TypeSpec;
  // types is a list of preloaded types. This list must include the type
  // referenced by spec.
  types: TypeInfo[];
  addOrUpdateTypeSpec: (input: TypeSpecInput) => void;
};

// StructFields renders a tree of fields of a struct type, allowing you to edit
// the struct's type spec. The struct's fields are roots, and they can be
// expanded into their subfields (for the fields that are structs).
export function StructSpecEditorInner(
  props: StructSpecEditorInnerProps,
): React.JSX.Element {
  const collectedExprs = useMemo(
    () =>
      new Map<string, string | undefined>(
        props.spec.collectExprs?.map((e) => [e.expr, e.column?.name]) ?? [],
      ),
    [props.spec],
  );

  const client = useApolloClient();
  const [tree, setTree] = useState<treeNodes | undefined>();
  // expandedNodes keeps track of the IDs of the nodes currently expanded. This
  // data is passed to the ExpressionTree component. The data is managed by this
  // AvailableVars components, as opposed to being entirely managed by the
  // ExpressionTree component, because rebuilding the tree needs to know what
  // nodes are expanded, to make sure that the respective child nodes are
  // present in the tree.
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  // typesMapRef is a cache keeping track of the loaded types. The map is
  // mutable - more types can be loaded over time.
  const typesMapRef = useRef<Map<string, TypeInfo>>(new Map());
  const typesMap = typesMapRef.current;
  for (const t of props.types) {
    maybeAddType(typesMap, t);
  }

  const typeName = props.spec.typeQualifiedName;
  const typ = typesMap.get(typeName);

  // Rebuild the tree whenever a new frame or type is being displayed, or when a
  // new node was expanded, or when a new type finished loading. We basically
  // want to rebuild the tree on every render, but building the tree is an async
  // function, so we have to call it from an effect.
  useEffect(() => {
    async function rebuild() {
      const tree = await buildTree(
        props.binaryID,
        typ,
        undefined /* functionVars */,
        expandedNodes,
        collectedExprs,
        typesMap,
        false /* showLines */,
        client,
      );
      setTree(tree);
    }

    void rebuild();
  }, [expandedNodes, collectedExprs, client, typesMap, props.binaryID, typ]);

  // If the tree is not loaded yet, don't render anything. The effect above will
  // kick in to build the tree and cause a re-render.
  if (!tree) {
    return <></>;
  }

  function onNodeChange(
    node: treeNode,
    checked: boolean,
    alias: string | undefined,
  ) {
    // Turn the expressions into their gql input form.
    const exprs = collectedExprs;
    if (checked) {
      exprs.set(node.fullExpr, alias);
    } else {
      exprs.delete(node.fullExpr);
    }

    const ce = Array.from(exprs.entries()).map(([expr, alias]) => ({
      expr: expr,
      column: alias
        ? {
            name: alias,
            hidden: false,
          }
        : undefined,
    }));

    props.addOrUpdateTypeSpec({
      typeQualifiedName: typeName,
      collectAll: false,
      collectExprs: ce,
    });
  }

  function onNodeDelete(node: treeNode) {
    const expr = node.fullExpr;
    const prefix = expr + ".";

    // Remove all expressions from the spec that start with `expr`.
    const newExprs = props.spec.collectExprs?.filter((e) => {
      const fieldExpr = e.expr;
      return fieldExpr != expr && !fieldExpr.startsWith(prefix);
    });

    props.addOrUpdateTypeSpec({
      ...props.spec,
      collectAll: false,
      collectExprs: newExprs ?? [],
    });
  }

  return (
    <ExpressionTree
      nodes={tree.tree}
      disabled={props.disabled}
      hoisting={props.hoisting}
      // When a node is toggled, we update the list of expanded nodes (thus
      // adding or removing the respective node's ID from the list).
      // TODO: When expanding a new node, this simple code is not great: if
      // loading the children of the newly-expanded node fails, the node's ID
      // will remain in the expanded list, and so the node will be rendered as
      // expanded and loading the children will be re-attempted on every
      // render, even if it fails over and over and pollutes the console.
      onNodeToggle={(expandedNodeIds) => setExpandedNodes(expandedNodeIds)}
      onNodeChange={(node, checked, alias) =>
        void onNodeChange(node, checked, alias)
      }
      onStaleNodeDelete={(node) => void onNodeDelete(node)}
      expandedNodeIDs={expandedNodes}
    />
  );
}
