import {Button, MenuItem, Select, Stack, Typography} from "@mui/material";
import {
  FlamegraphWidthStyle,
  GetMinimalAncestors,
  TreeNode,
} from "./FlamegraphData.ts";
import {FlamegraphState} from "./FlamegraphState.ts";

export function FlamegraphHeader({state}: {state: FlamegraphState}) {
  const {root} = state;
  const minimalFocusedNodes = new Set(
    GetMinimalAncestors(Array.from(state.focusedNodes)),
  );

  const isInFocus = (node: TreeNode) =>
    node.hasAncestorInNodes(minimalFocusedNodes);

  const hiddenNodesInView = [...state.hiddenNodes].filter(isInFocus);
  const numHiddenNodes = hiddenNodesInView.length;

  // Compute hidden goroutines without counting overlaps.
  const numHiddenGoroutines = GetMinimalAncestors(hiddenNodesInView).reduce(
    (partialSum, node) => partialSum + node.value,
    0,
  );

  // Remove from all focused goroutines the hidden ones.
  const numFocusedGoroutines =
    [...minimalFocusedNodes].reduce(
      (partialSum, node) => partialSum + node.value,
      0,
    ) - numHiddenGoroutines;

  const hiddenNodesInfo = numHiddenNodes > 0 && (
    <>
      , {numHiddenNodes} {numHiddenNodes === 1 ? "entry" : "entries"} hidden (
      {numHiddenGoroutines} goroutines)
      <Button
        onClick={() => state.setHiddenNodes(new Set())}
        disabled={numHiddenNodes === 0}
      >
        Clear
      </Button>
    </>
  );

  return (
    <div className="flame-menu">
      <Stack direction={"row"} spacing={1} alignItems="center">
        <Select
          label="Width style"
          value={state.widthStyle}
          onChange={(event) =>
            state.setWidthStyle(event.target.value as FlamegraphWidthStyle)
          }
        >
          {Object.entries(FlamegraphWidthStyle).map(([key, value]) => (
            <MenuItem value={value} key={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
        <Typography>
          Showing {numFocusedGoroutines} {state.unit}
          {root.value > 0 &&
            ` (${(100 * (numFocusedGoroutines / root.value)).toFixed(2)}%)`}
          {hiddenNodesInfo}
        </Typography>
      </Stack>
    </div>
  );
}
