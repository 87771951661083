import {
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {HelpCircle} from "@components/HelpCircle.tsx";
import ProcessFriendlyNameComposer from "./ProcessFriendlyNameComposer.tsx";
import React, {useContext, useState} from "react";
import {useSuspenseQuery} from "@apollo/client";
import {GET_PROGRAMS} from "@util/queries.tsx";
import {SpecContext} from "@providers/spec-provider.tsx";

// NewProgramCard renders that card that allows the user to add a new friendly name for program
export const NewProgramCard = () => {
  const spec = useContext(SpecContext);
  const {data: programsRes} = useSuspenseQuery(GET_PROGRAMS);

  const [selectedProgram, setSelectedProgram] = useState(
    undefined as string | undefined,
  );

  const programsWithoutFriendlyNameSpec = programsRes.getPrograms.filter(
    (program) => !spec.programs.some((p) => p.programName == program),
  );

  return (
    <Card variant="dense" color="default">
      <CardHeader title={"Add process friendly name for new program"} />
      <CardContent>
        <Stack direction="row" gap={1} alignItems="center" mb={2}>
          <Select
            sx={{width: "50%"}}
            value={selectedProgram ?? ""}
            onChange={(event) => {
              setSelectedProgram(event.target.value);
            }}
            displayEmpty
            disabled={!programsWithoutFriendlyNameSpec.length}
            color="secondary"
          >
            {!programsWithoutFriendlyNameSpec.length && (
              <MenuItem value={""} disabled>
                All known programs have already been configured with friendly
                names.
              </MenuItem>
            )}

            {/*Add a placeholder value that cannot be selected.*/}
            {programsWithoutFriendlyNameSpec.length && (
              <MenuItem
                data-value={""}
                value={""}
                disabled
                sx={{display: "none"}}
              >
                <Typography variant="body3" color="secondary">
                  Program
                </Typography>
              </MenuItem>
            )}
            {programsWithoutFriendlyNameSpec.length &&
              programsWithoutFriendlyNameSpec.map((program) => (
                <MenuItem key={program} value={program}>
                  {program}
                </MenuItem>
              ))}
          </Select>
          <HelpCircle tip="Select the program to which the process friendly name will apply." />
        </Stack>

        {selectedProgram && (
          <ProcessFriendlyNameComposer
            program={selectedProgram}
            onSave={() => {
              setSelectedProgram(undefined);
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};
