import {HelpCircle} from "@components/HelpCircle.tsx";
import {
  Box,
  Button,
  Tooltip,
  type TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import {useSnapshotState} from "@providers/snapshot-state.tsx";
import type React from "react";
import {useState} from "react";
import {type Goroutine, GoroutineStatus} from "src/__generated__/graphql.ts";
import {CircleInfo} from "src/util/icons.tsx";
import {
  type ProcessInfo,
  formatDurationNanos,
  type resolvedGoroutineGroup,
} from "src/util/util.ts";
import GoroutineLink from "./goroutine-link.tsx";

type GoroutineIDListProps = {
  // The goroutine IDs to display, grouped by process.
  goroutineGroups: resolvedGoroutineGroup[];
};

// GoroutineIDList renders a list of goroutine IDs. If the list is too large,
// only a couple are shown, with a "More" button. If the list consists of a
// single goroutine, extra information about that goroutine is displayed -- the
// status and wait time. If there are multiple goroutines, the longest wait time
// is displayed.
export default function GoroutineIDList(
  props: GoroutineIDListProps,
): React.JSX.Element {
  const snapshotState = useSnapshotState();
  const [numShown, setNumShown] = useState(5);

  // Flatten the goroutine groups such that each element has its process info.
  // Also track the longest wait time.
  type resolvedG = {process: ProcessInfo; g: Goroutine};
  const flattenedIDs: resolvedG[] = [];
  let longestWaitingG: (resolvedG & {waitingForNanos: number}) | undefined;
  props.goroutineGroups.forEach((group) => {
    group.goroutines.forEach((g) => {
      const resolved = {process: group.process, g};
      flattenedIDs.push(resolved);
      if (
        g.WaitForNanos &&
        g.WaitForNanos > (longestWaitingG?.waitingForNanos ?? 0)
      ) {
        longestWaitingG = {...resolved, waitingForNanos: g.WaitForNanos};
      }
    });
  });

  const singleGoroutine =
    flattenedIDs.length == 1 ? flattenedIDs[0].g : undefined;

  return (
    <span style={{display: "flex", flexWrap: "wrap"}}>
      {flattenedIDs.length} goroutines:
      {flattenedIDs.slice(0, numShown).map((gInfo, idx) => (
        <GoroutineLink
          key={idx}
          processID={gInfo.process.ProcessID}
          goroutineID={gInfo.g.ID.ID}
          sx={{ml: 1}}
          goroutine={gInfo.g}
          clickURL={snapshotState.goroutineURL(
            gInfo.process.ProcessID,
            gInfo.g.ID.ID,
          )}
          processClickURL={snapshotState?.setProcessURL(
            gInfo.process.ProcessID,
          )}
        />
      ))}
      {numShown < flattenedIDs.length && (
        <Button
          sx={{minWidth: 0, padding: "0 0 0 5px"}}
          onClick={() => setNumShown(numShown + 10)}
        >
          more…
        </Button>
      )}
      {/*If we're dealing with a single goroutine, display its status and wait time.*/}
      {singleGoroutine && (
        <Box sx={{ml: 2}}>
          status: {singleGoroutine.Status}
          {singleGoroutine.Status == GoroutineStatus.Waiting && (
            <>
              {singleGoroutine.WaitReason && (
                <>; reason: {singleGoroutine.WaitReason}</>
              )}
              ; waiting for:{" "}
              {singleGoroutine.WaitForNanos ? (
                <>{formatDurationNanos(singleGoroutine.WaitForNanos)}</>
              ) : (
                <>
                  unknown
                  <HelpCircle
                    small={true}
                    tip={
                      "Short wait times are not always known. The Go runtime only " +
                      "populates this information for a goroutine during a " +
                      "garbage-collection cycle. This goroutine was not waiting at the " +
                      "time of the last GC run."
                    }
                  />
                </>
              )}
            </>
          )}
        </Box>
      )}
      {/*If we're not dealing with a single goroutine, display the wait time of the longest-waiting g.*/}
      {!singleGoroutine && longestWaitingG && (
        <NoMaxWidthTooltip
          title={
            <span style={{whiteSpace: "nowrap"}}>
              longest waiting goroutine:{" "}
              <GoroutineLink
                processID={longestWaitingG.process.ProcessID}
                goroutineID={longestWaitingG.g.ID.ID}
                color={"orange"} // Override the default color of the links, so they look OK on the tooltip's dark background.
                clickURL={snapshotState.goroutineURL(
                  longestWaitingG.process.ProcessID,
                  longestWaitingG.g.ID.ID,
                )}
              />
            </span>
          }
        >
          <Box sx={{ml: 2, cursor: "pointer"}}>
            longest wait time:{" "}
            {formatDurationNanos(longestWaitingG.waitingForNanos)}
            <CircleInfo />
          </Box>
        </NoMaxWidthTooltip>
      )}
    </span>
  );
}

const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});
