import {skipToken, useSuspenseQuery} from "@apollo/client";
import {GET_BINARIES} from "../pages/Binaries/gqlHelper.ts";
import {GET_BINARIES_MATCHING_FUNCTION} from "@util/queries.tsx";
import React, {useEffect} from "react";
import {GetBinariesQuery} from "@graphql/graphql.ts";
import ListSubheader from "@mui/material/ListSubheader";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {MenuItem, Select, Stack, Typography} from "@mui/material";

type Props = {
  // The selected binary (i.e. the select value). undefined if no binary is
  // selected.
  binaryID?: string;

  // If snapshotID/logID and funcQualifiedName are set (they can only be set
  // together), only binaries corresponding to processes in the given snapshot
  // or log are listed. Only one of snapshotID and logID can be set.
  snapshotID?: number;
  logID?: number;
  funcQualifiedName?: string;

  // A callback called when the selected binary changes.
  setBinaryID: (id: string) => void;

  // If autoSelectSingleBinary is set and the list of candidate binaries only
  // contains a single binary, then that binary will be selected automatically
  // -- the setBinaryID() callback will be called and BinarySelector will not
  // render anything. The expectation is that, when autoSelectSingleBinary is
  // set, once the callback is called, the parent will re-render and not include
  // this BinarySelector anymore.
  autoSelectSingleBinary?: boolean;
};

// SelectorBinary is a select listing all the binaries and allowing the user to
// select one.
export function SelectorBinary(props: Props): React.JSX.Element {
  if (props.snapshotID && props.logID) {
    throw new Error(
      "both snapshotID and logID must not be set at the same time",
    );
  }
  const idSet = props.snapshotID != undefined || props.logID != undefined;
  if (idSet != (props.funcQualifiedName != undefined)) {
    throw new Error(
      "both snapshotID/logID and funcQualifiedName must be set or unset",
    );
  }

  // Read the binaries that can be selected. If snapshotID/funcQualifiedName are
  // not specified, these are all the binaries. If they are specified, then we
  // check which binaries match.

  // Read all the binaries and group them by program. Binaries that are not
  // attached to any programs will appear under the key undefined.
  const {data: allBinariesRes} = useSuspenseQuery(
    GET_BINARIES,
    props.snapshotID == undefined && props.logID == undefined ? {} : skipToken,
  );
  const {data: matchingBinariesRes} = useSuspenseQuery(
    GET_BINARIES_MATCHING_FUNCTION,
    props.snapshotID != undefined || props.logID != undefined
      ? {
          variables: {
            snapshotID: props.snapshotID,
            logID: props.logID,
            funcQualifiedName: props.funcQualifiedName!,
          },
        }
      : skipToken,
  );

  const binaries =
    allBinariesRes?.getBinaries ??
    matchingBinariesRes!.getBinariesMatchingFunction;

  // If there is only one matching binary and autoSelectSingleBinary is set,
  // select the binary. We have to do this in an effect because calling the
  // parent's state setter in a render causes a warning.
  useEffect(() => {
    if (binaries?.length == 1 && !!props.autoSelectSingleBinary) {
      // We expect the parent to re-render and not include this BinarySelector,
      // so this call shouldn't lead to a re-render loop.
      props.setBinaryID(binaries[0].id);
    }
  });

  const programToBinaries = new Map<
    string | undefined,
    GetBinariesQuery["getBinaries"]
  >();

  for (const b of binaries) {
    if (b.programs.length == 0) {
      const bins = programToBinaries.get(undefined) ?? [];
      bins.push(b);
      programToBinaries.set(undefined, bins);
      continue;
    }
    for (const p of b.programs) {
      const bins = programToBinaries.get(p) ?? [];
      bins.push(b);
      programToBinaries.set(p, bins);
    }
  }

  // Creating menu items for the binaries dropdown consisting of the binaries
  // and the program separators.
  const binariesDropdownEntries: React.ReactElement[] = [];
  for (const [program, bins] of programToBinaries) {
    let header: React.JSX.Element;
    if (program != undefined) {
      header = <ListSubheader key={program}>{program}</ListSubheader>;
    } else {
      header = (
        <ListSubheader key={"<no program>"}>
          Binaries not attached to any programs
          <HelpCircle
            tip={
              "These binaries are not found in any snapshots and are not currently being " +
              "used by any processes reported by agents."
            }
          />
        </ListSubheader>
      );
    }
    binariesDropdownEntries.push(header);

    binariesDropdownEntries.push(
      ...bins.map(
        (bin): React.JSX.Element => (
          <MenuItem key={bin.id} value={bin.id}>
            {bin.userName}
          </MenuItem>
        ),
      ),
    );
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Select
        color="secondary"
        sx={{width: "300px"}}
        value={props.binaryID ?? ""}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              backgroundColor: "transparent",
            },
            "& .MuiMenu-list": {
              backgroundColor: (theme) => theme.palette.background.component,
              borderRadius: "10px",
              marginTop: "10px",
              "& .MuiListSubheader-root": {
                backgroundColor: (theme) => theme.palette.background.component,
              },
            },
          },
        }}
        displayEmpty
        onChange={(event) => {
          const binaryID = event.target.value;
          props.setBinaryID(binaryID);
        }}
      >
        <MenuItem value={""} disabled sx={{display: "none"}}>
          <Typography variant="body3" color="secondary">
            Select binary
          </Typography>
        </MenuItem>

        {binariesDropdownEntries}
      </Select>
      <HelpCircle
        tip={
          `Select a binary for listing functions, types and variables.\n` +
          `Editing certain elements of the spec requires a binary to be selected. The debug information of the selected binary is used.`
        }
      />
    </Stack>
  );
}
