import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {HelpCircle} from "@components/HelpCircle.tsx";
import {
  matchTableAutocompleteOptions,
  tablesAutocompleteOption,
  tablesToAutocompleteOptions,
} from "src/components/tables/util.tsx";
import {TableReference, TableSchema} from "src/__generated__/graphql.ts";
import {Stack} from "@mui/material";

function getOptionKey(option: tablesAutocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  return option.key();
}

function getOptionLabel(option: tablesAutocompleteOption | string): string {
  if (typeof option === "string") {
    return option;
  }
  return option.label();
}

function isOptionEqualToValue(
  opt: tablesAutocompleteOption,
  value: tablesAutocompleteOption | string,
): boolean {
  if (typeof value == "string") {
    return false;
  }
  return opt.equals(value);
}

// TablesFilter renders the Autocomplete component for filtering tables by table
// name, function name, package, module, columns, etc.
export function TablesFilter(props: {
  tables: TableSchema[];
  filterValue: tablesAutocompleteOption | string | null;
  setFilterValue: (value: tablesAutocompleteOption | string | null) => void;
  setSelectedTableURL: (tableReference: TableReference) => string;
}): React.JSX.Element {
  const [filterInputValue, setFilterInputValue] = useState<string>("");
  const navigate = useNavigate();

  // Generate the autocomplete options.
  const autocompleteOptions = tablesToAutocompleteOptions(props.tables, {
    includeColumns: true,
    includeTypes: true,
    includeModules: true,
    includePackages: true,
  });
  // If there is a filter, make sure there is an option for it (there might not
  // currently be for package prefix filters, as those get generated
  // dynamically). The autocomplete component insists on having an option
  // corresponding to the current value.
  if (props.filterValue != null && typeof props.filterValue !== "string") {
    const f = props.filterValue;
    if (!autocompleteOptions.some((opt) => isOptionEqualToValue(opt, f))) {
      autocompleteOptions.push(f);
    }
  }

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Autocomplete
        options={autocompleteOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search tables"
            color="secondary"
          />
        )}
        // We take control over the filtering process so that we can do our
        // own matching of the query to the suggestions. Also, the suggestions
        // change in response to the query -- parts of the string get
        // highlighted. Also, some suggestions (the package prefixes) are
        // generated based on the query.
        filterOptions={(options, state) =>
          matchTableAutocompleteOptions(options, state.inputValue)
        }
        renderOption={(props, option: tablesAutocompleteOption) =>
          option.render(props)
        }
        getOptionKey={getOptionKey}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(_, value) => {
          if (value == null || typeof value === "string") {
            props.setFilterValue(value);
            return;
          }

          if (value.type == "table") {
            navigate(props.setSelectedTableURL(value.tableReference));
            setFilterInputValue("");
          } else {
            props.setFilterValue(value);
          }
        }}
        value={props.filterValue}
        inputValue={filterInputValue}
        onInputChange={(_event, value) => setFilterInputValue(value)}
        freeSolo={true}
        sx={{flexGrow: 1, minWidth: 300}}
      />
      <HelpCircle
        tip={
          "Filter the tables by table name, function name, function type, " +
          "function package, columns or module name. Filtering on a single " +
          "table (or the function corresponding to that table) will open the " +
          "table directly."
        }
      />
    </Stack>
  );
}
